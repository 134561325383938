import { Container } from './styles'
import reactIcon from '../../assets/react-icon.svg'
import linkedin from '../../assets/linkedin.svg'
//import githubIcon from '../../assets/github.svg'
import whatsapp from '../../assets/whatsapp.svg'
import telegram from '../../assets/telegram.svg'
import instagramIcon from '../../assets/instagram.svg'
// import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      <a href="https://ewin.cloud" className="logo">
        <span>www.ewin.</span>
        <span>cloud</span>
      </a>  
      {/* <div>
        <p>
         This Website was made with <img src={reactIcon} alt="React" />
           <span>❤️</span> 
        </p> 
      </div> */}
      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/abdul-farith-0580381a5"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin" />
        </a>
       {/* <a
          href="https://github.com/CodeVinayak/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>*/}
        <a
          href="https://api.whatsapp.com/send/?phone=%2B919788693020&text=Hi+Farith"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" />
        </a>
        <a
          href="https://t.me/abdulfarith"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" />
        </a>
        <a
          href="https://www.instagram.com/abdul__farith_5?igsh=MTYydGZwZzN6MXdlZg=="
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a>
      </div>
    </Container>
  )
}
