import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { FaCommentDots, FaTimes } from "react-icons/fa";
import { sendMessageToGroq } from "./groqService"; 

interface SendButtonProps {
  visible: boolean;
}
const bounceAndMove = keyframes`
  0% {
    top: 20px;
    right: 20px;
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    top: calc(100vh - 80px); /* Moving towards the bottom */
    right: 20px;
    transform: translateY(-250px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    top:auto;
    bottom: 20px; 
    right: 20px;
    transform: translateY(0);
  }
`;

const FloatingButton = styled.button<{ isBouncing: boolean }>`
  position: fixed;
  bottom: ${({ isBouncing }) => (isBouncing ? 'auto' : '20px')}; /* Fixed to bottom after bouncing */
  right: 20px;
  background-color: var(--purple);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;

  ${({ isBouncing }) =>
    isBouncing &&
    css`
      animation: ${bounceAndMove} 2s forwards;
      top: 20px; /* Start at the top */
    `}

  &:hover {
    background-color: var(--pink);
    transform: scale(1.1);
  }

  @media (max-width: 1080px) {
    width: 70px;
    height: 70px;
    font-size: 20px;
  }

  @media (max-width: 740px) {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  @media (max-width: 340px) {
    width: 45px;
    height: 45px;
    font-size: 18px;
  }
`;


const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  backdrop-filter: blur(10px); /* blur effect */
  z-index: 999; /* layer it beneath the chat container */
  opacity: ${({ visible }: SendButtonProps) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }: SendButtonProps) => (visible ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
`;




const fadeInOut = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
`;

const PopUp = styled.div`
  position: fixed;
  bottom: 90px;
  right: 30px;
  background-color: var(--pop-up-bg);
  color: var(--pop-up-text);
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1001;
  animation: ${fadeInOut} 10s ease-in-out forwards;

  @media (max-width: 1080px) {
    right: 40px;
    font-size: 13px;
    bottom: 120px;
  }

  @media (max-width: 740px) {
    right: 20px;
    font-size: 13px;
    bottom: 90px;
  }

  @media (max-width: 340px) {
    right: 15px;
    font-size: 12px;
    bottom: 60px;
  }
`;

const ChatContainer = styled.div<{ viewportHeight: number, keyboardActive: boolean }>`
  position: fixed;
  bottom: 12%;
  right: 20px;
  width: 30%;
  max-width: 90%;
  height: ${({ viewportHeight, keyboardActive }) => 
    `calc(${viewportHeight}px - ${keyboardActive ? '15%' : '25%'})`}; /* Dynamically adjust height */
  max-height: 90%;
  background-color: var(--tile-bg);
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1002;
  transition: transform 0.3s ease, opacity 0.3s ease;

  @media (max-width: 1080px) {
    width: 60%;
    bottom: 8%;
    right: 5%;
  }

  @media (max-width: 960px) {
    width: 60%;
    bottom: 10%;
    right: 5%;
  }

  @media (max-width: 740px) {
    width: 90%;
    bottom: 9%;
    right: 5%;
  }

  @media (max-width: 340px) {
    width: 100%;
    bottom: 8%;
    right: 5%;
  }
`;



const ChatHeader = styled.div`
  padding: 15px;
  background-color: var(--tile-bg);
  color: var(--purple);
  font-weight: bold;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 16px;

  @media (max-width: 960px) {
    padding: 14px;
    font-size: 15px;
  }

  @media (max-width: 740px) {
    padding: 12px;
    font-size: 14px;
  }

  @media (max-width: 340px) {
    padding: 10px;
    font-size: 13px;
  }
`;

const UserMessage = styled.p`
  align-self: flex-end;
  background-color: var(--chat-bg);
  color: var(--tile-text);
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
  margin: 5px 0;
  letter-spacing: 0.7px;
  

  @media (max-width: 960px) {
    padding: 8px 12px;
  }

  @media (max-width: 740px) {
    padding: 7px 10px;
  }

  @media (max-width: 340px) {
    padding: 6px 8px;
  }
`;

const BotMessage = styled.p`
  align-self: flex-start;
  background-color: var(--chat-bg);
  color: var(--tile-text);
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
  white-space: pre-wrap;  // Preserve formatting
  margin: 3px 0;
  letter-spacing: 0.7px;
 
  @media (max-width: 960px) {
    padding: 8px 12px;
  }
 
  @media (max-width: 740px) {
    padding: 7px 10px;
  }
 
  @media (max-width: 340px) {
    padding: 6px 8px;
  }

  // Styles for links
  a {
    color: var(--link-color); // Customize the color as per your theme
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;


const ChatBody = styled.div`
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: var(--tile-bg);
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;



    @media (max-width: 1080px) {
    padding: 16px;
    font-size: 18px;
  }

  @media (max-width: 960px) {
    padding: 14px;
    font-size: 16px;
  }

  @media (max-width: 740px) {
    padding: 12px;
    font-size: 16px;
  }

  @media (max-width: 340px) {
    padding: 10px;
    font-size: 16px;
  }
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color:  var(--tile-bg);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 960px) {
    padding: 9px 14px;
  }

  @media (max-width: 740px) {
    padding: 8px 12px;
  }

  @media (max-width: 340px) {
    padding: 7px 10px;
  }
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 15px;
  border-radius: 5px;
  background-color: var(--chat-bg);
  color: var(--tile-text);

    @media (max-width: 1080px) {
    padding: 10px;
    font-size: 18px;
  }
  @media (max-width: 960px) {
    padding: 9px;
    font-size: 15px;
  }

  @media (max-width: 740px) {
    padding: 8px;
    font-size: 15px;
  }

  @media (max-width: 340px) {
    padding: 7px;
    font-size: 15px;
  }
`;

const SendButton = styled.button<SendButtonProps>`
  background-color: var(--purple);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  margin-left: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}

  &:hover {
    background-color: var(--pink);
  }

  @media (max-width: 960px) {
    padding: 6px 12px;
    margin-left: 10px;
  }

  @media (max-width: 740px) {
    padding: 5px 10px;
    margin-left: 8px;
  }

  @media (max-width: 340px) {
    padding: 4px 8px;
    margin-left: 6px;
  }
`;

const TypingIndicator = styled.div`
  align-self: flex-start;
  color: var(--tile-text);
  padding: 8px 12px;
  border-radius: 10px;
  background-color: var(--chat-bg);
  font-style: italic;
`;

const PredefinedPromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin as needed */
`;

const PredefinedPrompt = styled.div`
  color: var(--prompt-text);
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
  margin: 5px 0;
  cursor: pointer;
  background-color: var(--button-hover-bg);

  &:hover {
    background-color: var(--chat-bg);
  }

  @media (max-width: 960px) {
    padding: 7px 10px;
  }

  @media (max-width: 740px) {
    padding: 6px 8px;
  }

  @media (max-width: 340px) {
    padding: 5px 7px;
  }
`;

export function ChatButton() {
  const [showPopUp, setShowPopUp] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isBouncing, setIsBouncing] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<HTMLInputElement>(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [userScrolling, setUserScrolling] = useState(false);

  function formatMessageWithLinks(message: string) {
    // Updated regex to match URLs and email addresses
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
  
    return message.split(urlPattern).map((part, index) => {
      if (part.match(urlPattern)) {
        if (part.includes("@")) {
          return (
            <a href={`mailto:${part}`} key={index}>
              {part}
            </a>
          );
        } else {
          let cleanPart = part;
          if (part.endsWith(".")) {
            cleanPart = part.slice(0, -1);
          }
          const href = cleanPart.startsWith("www.") ? `http://${cleanPart}` : cleanPart;
          return (
            <a href={href} key={index} target="_blank" rel="noopener noreferrer">
              {cleanPart}
            </a>
          );
        }
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  }

  const predefinedPrompts = [
    "Pitch yourself in 1 minute.",
    "What are you passionate about?",
    "How can we connect?"
  ];

  useEffect(() => {
    sessionStorage.removeItem("chatHistory");

    const timer = setTimeout(() => {
      setShowPopUp(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.visualViewport?.height || window.innerHeight;
      setViewportHeight(newHeight);
      setKeyboardActive(newHeight < window.innerHeight);

      if (chatBodyRef.current && !userScrolling) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, [userScrolling]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatBodyRef.current) {
        const atBottom =
          chatBodyRef.current.scrollTop + chatBodyRef.current.clientHeight >=
          chatBodyRef.current.scrollHeight - 5;
        setUserScrolling(!atBottom);
      }
    };

    if (chatBodyRef.current) {
      chatBodyRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatBodyRef.current) {
        chatBodyRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isChatOpen || keyboardActive) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isChatOpen, keyboardActive]);

  useEffect(() => {
    // Stop the bouncing effect after 5 seconds
    const timer = setTimeout(() => {
      setIsBouncing(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async (message?: string) => {
    const messageToSend = message || inputValue;
    if (messageToSend.trim() === "") return;

    if (!firstMessageSent) {
      setFirstMessageSent(true);
    }

    const updatedMessages = [...chatMessages, `User: ${messageToSend}`];
    setChatMessages(updatedMessages);
    sessionStorage.setItem("chatHistory", JSON.stringify(updatedMessages));

    setInputValue("");
    setIsBotTyping(true);

    const response = await sendMessageToGroq(messageToSend);

    const finalMessages = [...updatedMessages, `Bot: ${response}`];
    setChatMessages(finalMessages);
    sessionStorage.setItem("chatHistory", JSON.stringify(finalMessages));
    setIsBotTyping(false);
  };

  useEffect(() => {
    const storedMessages = sessionStorage.getItem("chatHistory");
    if (storedMessages) {
      setChatMessages(JSON.parse(storedMessages));
    }
  }, []);


  useEffect(() => {
    if (chatBodyRef.current && !userScrolling) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }

    if (chatInputRef.current && !isBotTyping) {
      chatInputRef.current.focus();
    }
  }, [chatMessages, isBotTyping, userScrolling]);

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (keyboardActive) {
      e.preventDefault();
      e.target.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <>
      {showPopUp && <PopUp>Chat with My AI Version</PopUp>}
      <FloatingButton onClick={toggleChat} isBouncing={isBouncing}>
        {isChatOpen ? <FaTimes /> : <FaCommentDots />}
      </FloatingButton>
      <BackgroundOverlay visible={isChatOpen} />
      {isChatOpen && (
        <ChatContainer
          viewportHeight={viewportHeight}
          keyboardActive={keyboardActive}
        >
          <ChatHeader>Farith's TWIN</ChatHeader>
          <ChatBody ref={chatBodyRef}>
            {!firstMessageSent && (
              <PredefinedPromptContainer>
                {predefinedPrompts.map((prompt, index) => (
                  <PredefinedPrompt key={index} onClick={() => handleSendMessage(prompt)}>
                    {prompt}
                  </PredefinedPrompt>
                ))}
              </PredefinedPromptContainer>
            )}
            {chatMessages.map((msg, index) => {
              if (msg.startsWith("User:")) {
                return (
                  <UserMessage key={index}>
                    {msg.replace("User: ", "")}
                  </UserMessage>
                );
              } else {
                const botSentences = msg.replace("Bot: ", "").split(". ");
                return botSentences.map((sentence, i) => (
                  <BotMessage key={`${index}-${i}`}>
                    {formatMessageWithLinks(sentence.trim() + (i < botSentences.length - 1 ? "." : ""))}
                  </BotMessage>
                ));
              }
            })}
            {isBotTyping && <TypingIndicator>Farith is typing...</TypingIndicator>}
          </ChatBody>
          <ChatInputContainer>
            <ChatInput
              ref={chatInputRef}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              placeholder="Type a message..."
            />
            <SendButton
              visible={inputValue.trim() !== "" && !isBotTyping}
              onClick={() => handleSendMessage()}
            >
              Send
            </SendButton>
          </ChatInputContainer>
        </ChatContainer>
      )}
    </>
  );
}